<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card form-body">
        <div class="card-body">
          <form>
            <div class="d-flex">
              <legend><h4>Promocode Information</h4></legend>

              <div class="form-group">
                <label for="">Active</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="is_active"
                    class="switch"
                    id="status_switch"
                  />
                  <label for="status_switch"></label>
                </span>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Title <span class="text-danger">*</span></label>
                  <input
                    v-model="title"
                    type="text"
                    class="form-control"
                    :class="errors['title'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['title']" class="text-danger">{{
                    errors["title"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Company Name</label>
                  <input
                    v-model="company_name"
                    type="text"
                    class="form-control"
                    :class="
                      errors['company_name'] ? 'border border-danger' : ''
                    "
                  />
                  <span v-if="errors['company_name']" class="text-danger">{{
                    errors["company_name"][0]
                  }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-md-6
              "
              >
                <div class="form-group">
                  <label for=""
                    >Discount(%) <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="discount_percentage"
                    type="number"
                    min="0"
                    class="form-control"
                    :class="
                      errors['discount_percentage']
                        ? 'border border-danger'
                        : ''
                    "
                  />
                  <span
                    v-if="errors['discount_percentage']"
                    class="text-danger"
                    >{{ errors["discount_percentage"][0] }}</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""
                    >Valid Date <span class="text-danger">*</span></label
                  >

                  <date-picker
                    valueType="MM/DD/YYYY"
                    v-model="expireDate"
                    format="MM/DD/YYYY"
                    :disabled-date="notAfterToday"
                    class="form_control"
                    range
                  ></date-picker>
                  <span v-if="errors['valid_from']" class="text-danger">{{
                    errors["valid_from"][0]
                  }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            @click="update('kt_update_company_data')"
            ref="kt_update_company_data"
            style="float: right"
            v-if="isEdit"
          >
            <i class="fa fa-check"></i> Update
          </button>

          <button
            class="btn btn-success ml-3"
            style="float: right"
            v-if="!isEdit"
            ref="kt_save_company"
            @click="store('S', 'kt_save_company')"
          >
            <i class="fa fa-check"></i> Save
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-success"
            @click="store('SAA', 'kt_save_add_another_company')"
            style="float: right"
            v-if="!isEdit"
          >
            <i class="fa fa-check"></i> Save and add another
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  STORE_PROMOCODE,
  GET_PROMOCODE_DATA,
  UPDATE_PROMOCODE
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Promocodes",
          route: "client.promocode"
        },
        {
          id: 3,
          title: "Create New Promocode",
          route: ""
        }
      ],
      title: "",
      discount_percentage: 0,
      company_name: "",
      is_active: false,
      expireDate: "",
      errors: []
    };
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.isEdit = this.$route.name == "client.promocode.edit" ? true : false;
    var id = this.$route.params.id;
    if (this.isEdit) {
      this.breadCrumbs[2].title = "";
      this.$store
        .dispatch(GET_PROMOCODE_DATA, id)
        .then(data => {
          this.breadCrumbs[2].title = "Edit | " + data.title;
          this.title = data.title;
          this.discount_percentage = data.discount_percentage;
          this.is_active = data.is_active;
          this.company_name = data.company_name;
          this.expireDate = [
            this.$moment(data.valid_from).format("MM/DD/YYYY"),
            this.$moment(data.valid_to).format("MM/DD/YYYY")
          ];
        })
        .catch(() => {
          this.$toastr.e("Company detail not found!");
          this.$router.push({ name: "client.promocode" });
        });
    }
  },
  methods: {
    notAfterToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.promocode",
        params: { client_slug: client_slug }
      });
    },
    clearForm() {
      (this.discount_percentage = 0),
        (this.is_active = false),
        (this.expireDate = "");
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    update(ref) {
      var valid_from = "";
      var valid_to = "";
      this.expireDate = this.expireDate == "," ? "" : this.expireDate;
      if (this.expireDate != "") {
        var exp = this.expireDate;
        valid_from = this.$moment(exp[0]).format("YYYY-MM-DD");
        valid_to = this.$moment(exp[1]).format("YYYY-MM-DD");
      }
      this.errors = [];
      this.loadingButton(ref);
      // var data_ = {
      //   title: this.title,
      //   discount_percentage: this.discount_percentage,
      //   is_active: this.is_active,
      //   valid_from: valid_from,
      //   valid_to: valid_to
      // };
      this.$store
        .dispatch(UPDATE_PROMOCODE, {
          id: this.$route.params.id,
          title: this.title,
          discount_percentage: this.discount_percentage,
          is_active: this.is_active,
          valid_from: valid_from,
          valid_to: valid_to,
          company_name: this.company_name
        })
        .then(data => {
          if (data.code == 200) {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            this.$router.push({ name: "client.promocode" });
          } else {
            this.$toastr.w(data.msg);
            this.closeBtnLoad(ref);
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
    store(saveType, ref) {
      var valid_from = "";
      var valid_to = "";
      this.expireDate = this.expireDate == "," ? "" : this.expireDate;
      if (this.expireDate != "") {
        var exp = this.expireDate;
        valid_from = this.$moment(exp[0]).format("YYYY-MM-DD");
        valid_to = this.$moment(exp[1]).format("YYYY-MM-DD");
      }
      this.errors = [];
      this.loadingButton(ref);
      var data_ = {
        id: 0,
        discount_percentage: this.discount_percentage,
        is_active: this.is_active,
        company_name: this.company_name,
        title: this.title,
        valid_from: valid_from,
        valid_to: valid_to
      };
      this.$store
        .dispatch(STORE_PROMOCODE, data_)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({ name: "client.promocode" });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
